import Layout from 'components/layouts/main'
import Head from 'next/head'

export default function Home() {
  return (
    <>
      <Head>
        <title key="title">
          TotalSystemCare | Automatic PC Maintenance Software
        </title>
      </Head>
      <Layout>
        <section className="hero-section">
          <div className="hero-container">
            <div className="hero-section__content">
              <div className="hero-section__left">
                <h1>
                  TotalSystemCare can clean and optimize your PC in minutes.
                </h1>
                <p>
                  TotalSystemCare was designed to automatically detect areas
                  that need optimization or cleaning in order to get your PC
                  running efficiently.
                </p>
                <a href="/download" className="button">
                  Download Now
                </a>
              </div>
              <div className="hero-section__right">
                <img src="img/home/hero-img.svg" alt="" />
              </div>
            </div>
            <a href="/download" className="hero-section__download-button">
              <img src="img/home/windows.svg" alt="" />
              <div className="hero-section__download-button-left">
                <p>AVAILABLE</p>
                <span>For Windows 11, 10, 8 and 7</span>
              </div>
            </a>
          </div>
        </section>
        <section className="grid-section">
          <div className="container">
            <div className="grid-section__content">
              <h2>
                TotalSystemCare will easily clean and optimize your computer by
                removing invalid, unnecessary and outdated clutter on your PC.
              </h2>
              <div className="grid-section__grid">
                <div className="grid-section__item">
                  <img src="/img/home/grid-img1.svg" alt="" />
                  <b>Easy to use</b>
                  <p>
                    TotalSystemCare was designed to make it easy for our
                    customers to use. A simple and user-friendly interface
                    allows even the novice user to handle their computing issues
                    with a few clicks of the mouse.
                  </p>
                </div>
                <div className="grid-section__item">
                  <img src="/img/home/grid-img2.svg" alt="" />
                  <b>Customer Care</b>
                  <p>
                    We are here to help! Our Customer Care Team will always lend
                    a helping hand to make sure our users are satisfied. Being
                    good isn&apos;t enough, we pride ourselves on being nothing
                    but the best.
                  </p>
                </div>
                <div className="grid-section__item">
                  <img src="/img/home/grid-img3.svg" alt="" />
                  <b>Powerful Technology</b>
                  <p>
                    TotalSystemCare is based on a sophisticated, easy to use
                    technology that automatically detects all available
                    optimizations to make sure your PC is properly functioning
                    and is running as efficiently as possible.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="mac-section">
          <div className="container">
            <div className="mac-section__content">
              <h2 id="about_tsc">
                TotalSystemCare Provides a Simple Solution to Complicated PC
                Issues.
              </h2>
              <div className="mac-section__item">
                <img src="/img/home/mac-img1.svg" alt="" />
                <div className="mac-section__right">
                  <b>Optimization Made Easy</b>
                  <p>
                    Over time, Internet browsers, Windows components and other
                    software programs can develop issues causing them to cause
                    conflicts, crashes, and lead to reduced functionality.
                    TotalSystemCare scans for multiple areas of potential
                    instability, including missing and corrupted virtual devices
                    and drivers, program DLL issues, and corrupt and missing
                    help libraries thus simplifying the optimization process for
                    you.
                  </p>
                </div>
              </div>
              <div className="mac-section__item">
                <img src="/img/home/mac-img2.svg" alt="" />
                <div className="mac-section__right">
                  <b>Added Security Features</b>
                  <p>
                    From blocking popups to security checks for issues with AV,
                    firewall, or Chrome extensions, TotalSystemCare has got you
                    covered. Additional features include a Privacy scan that can
                    help to protect your privacy by finding and removing all
                    traces of online and offline activities. TotalSystemCare can
                    even review important Windows privacy settings and easily
                    update them for maximum security.
                  </p>
                </div>
              </div>
              <div className="mac-section__item">
                <img src="/img/home/mac-img3.svg" alt="" />
                <div className="mac-section__right">
                  <b>Tools in the Toolbox</b>
                  <p>
                    The toolbox section of TotalSystemCare allows for easy
                    access to important reports and monitoring devices. With a
                    few clicks of the mouse you can easily collect and manage
                    your software license keys, get an up to date list of the
                    device drivers on your PC, manage popup notification and set
                    automatic scans of your PC to ensure you are always running
                    your PC at maximum efficiency.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="option-section">
          <div className="small-container">
            <div className="option-section__content">
              <h2>
                Loaded With Features. TotalSystemCare Has Got You Covered.
              </h2>
              <div className="option-section__item">
                <div className="option-section__left">
                  <b>Cleaning</b>
                  <p>
                    TotalSystemCare will help to clean and optimize your PC so
                    you can maximize the efficiency of your machine. Windows
                    registry stores settings and options for your operating
                    system. Over time, the registry may become cluttered with
                    invalid and outdated data. TotalSystemCare removes invalid
                    and outdated items cluttering the Windows registry and
                    potentially slowing down the PC. Additionally,
                    TotalSystemCare can free up disk space and protect your
                    privacy by removing Internet browser history and tracking
                    cookies.
                  </p>
                </div>
                <img src="/img/home/cleaning.svg" alt="" />
              </div>
              <div className="option-section__item">
                <div className="option-section__left">
                  <b>Security</b>
                  <p>
                    TotalSystemCare can provide complimentary security features
                    to your PC. It can find and replace old and unwanted browser
                    extensions, protect your privacy by detecting and removing
                    all traces of online and offline activities, block those
                    annoying and unwanted popup windows and can check to see if
                    your network is secure. TotalSystemCare will also check for
                    issues with your Anti-Virus, firewall, and Chrome browser
                    extensions. TotalSystemCare may not be the first line of
                    defense, but it helps to provide a second feature rich layer
                    to help keep you secure.
                  </p>
                </div>
                <img src="/img/home/security.svg" alt="" />
              </div>
              <div className="option-section__item">
                <div className="option-section__left">
                  <b>Optimization</b>
                  <p>
                    TotalSystemCare is loaded with features to optimize the
                    performance of your PC. It can boost your PC&apos;s startup
                    time by reviewing and removing unnecessary programs,
                    identify and automatically repair Windows issues, analyze
                    disk space allocation, and review recent notifications
                    regarding possible PC performance issues.
                    TotalSystemCare&apos;s advanced scan technology can also
                    clean up additional files, including old Windows updates,
                    service packs, and old restore points.
                  </p>
                </div>
                <img src="/img/home/optimization.svg" alt="" />
              </div>
              <div className="option-section__item">
                <div className="option-section__left">
                  <b>Monitoring</b>
                  <p>
                    TotalSystemCare will monitor your system to keep an eye on
                    recent notifications regarding possible PC performance
                    issues. It can give your PC a memory boost by automatically
                    monitoring and shutting down any running background
                    applications which can help to give an immediate PC boost.
                    The built-in-stability report breaks down the number and
                    frequency of application crashes to help spot potential
                    issues. Overall, TotalSystemCare will monitor and optimize
                    your PC allowing you to get more done.
                  </p>
                </div>
                <img src="/img/home/monitoring.svg" alt="" />
              </div>
            </div>
          </div>
        </section>
        <section className="blue-section">
          <div className="container fix-container">
            <div className="blue-section__conent">
              <h2>Important Facts on Why TotalSystemCare is Awesome.</h2>
              <div className="blue-section__grid">
                <div className="blue-section__item">
                  <img src="/img/home/grid-img4.svg" alt="" />
                  <b>AppEsteem Approved</b>
                  <p>
                    Appesteem is the leading application utility certification
                    in the world. TotalSystemCare has passed AppEsteem&apos;s
                    rigorous testing and certification process which gives our
                    users confidence when using our products.
                  </p>
                </div>
                <div className="blue-section__item">
                  <img src="/img/home/grid-img5.svg" alt="" />
                  <b>Money Back Guarantee</b>
                  <p>
                    Our customer care team will always lend a helping hand to
                    make sure our users are satisfied. We offer a 30 day money
                    back guarantee to all customers. If you are not happy,
                    you&apos;ll get a full refund, no questions asked.
                  </p>
                </div>
                <div className="blue-section__item">
                  <img src="/img/home/grid-img6.svg" alt="" />
                  <b>System Requirements</b>
                  <p>
                    TotalSystemCare is compatible with Windows 11, 10, 8, 8.1, 7
                    (32/64 bit). The minimum system requirements to run
                    TotalSystemCare are: Intel or AMD Dual Core or higher; 2GB
                    RAM, 100 MB HDD free space; Full Administrative Rights.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="spoller-section">
          <div className="footer-container">
            <h2>Frequently asked questions</h2>
            <div className="spoller-section__content">
              <div className="spoilers-block _scr-item _spoilers _one">
                <details className="spoilers-block__item">
                  <summary className="spoilers-block__title">
                    Why is my computer slow?
                  </summary>
                  <div className="spoilers-block__body">
                    There could be several reasons why your PC is slow or
                    sluggish? In some cases, your PC can become cluttered by
                    certain types of software, different programs fighting for
                    resources, and other unforeseen issues that may not be easy
                    to recognize. TotalSystemCare was designed specifically for
                    these types of instances to help regain your PC&apos;s
                    efficiency, speed, and responsiveness.
                  </div>
                </details>

                <details className="spoilers-block__item">
                  <summary className="spoilers-block__title">
                    Will running TotalSystemCare improve PC performance?{' '}
                  </summary>
                  <div className="spoilers-block__body">
                    TotalSystemCare is designed to help increase PC performance.
                    Results will vary based on your PC vs other PCs. Keep in
                    mind that TotalSystemCare is more than just an optimization
                    utility, it has numerous cleaning tools as well as security
                    features that may be of benefit to you.
                  </div>
                </details>

                <details className="spoilers-block__item">
                  <summary className="spoilers-block__title">
                    What are the minimum system requirements to run
                    TotalSystemCare?{' '}
                  </summary>
                  <div className="spoilers-block__body">
                    TotalSystemCare is compatible with Windows 11, 10, 8, 8.1, 7
                    (32/64 Bit). The minimum system requirements to run
                    TotalSystemCare are as follows: Intel or AMD Dual Core or
                    higher; 2GB RAM, 100 MB HDD free space; Full Administrative
                    Rights.
                  </div>
                </details>

                <details className="spoilers-block__item">
                  <summary className="spoilers-block__title">
                    What are some of the features and benefits of
                    TotalSystemCare?
                  </summary>
                  <div className="spoilers-block__body">
                    <h4
                      style={{
                        marginBottom: '10px',
                        fontSize: '1.1rem',
                        color: 'rgb(46, 46, 46)',
                        fontFamily:
                          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                      }}
                    >
                      Cleaning Capabilities
                    </h4>
                    <ul
                      style={{
                        fontSize: '14px',
                        color: 'rgb(46, 46, 46)',
                        fontFamily:
                          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                      }}
                    >
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Registry items
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -The Windows registry stores settings and options for
                          Microsoft Windows. Over time, the registry becomes
                          cluttered with invalid and outdated data. PC Cleaner
                          removes Invalid and outdated items cluttering the
                          Windows registry and slowing down the PC.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Internet items
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Free up disk space and protect your privacy by
                          removing Internet browser history, tracking cookies,
                          etc.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Chat history
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Protect your privacy by removing Logs of
                          conversations in Skype, Meetings, Slack, and more.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        System Clutter
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Removes residual files, including unnecessary log
                          files, application cache files, items left over when
                          programs are uninstalled, and system memory dumps.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Invalid Shortcuts
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Shortcuts to programs no longer on the computer.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Large Files
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Find and remove large files you no longer need taking
                          up precious disk space.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Residual Files
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -When you remove an application, there are often
                          residual files leftover on the computer. PC Cleaner
                          finds and removes these leftover files.
                        </p>
                      </li>
                    </ul>
                    <h4
                      style={{
                        marginBottom: '10px',
                        fontSize: '1.1rem',
                        color: 'rgb(46, 46, 46)',
                        fontFamily:
                          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                      }}
                    >
                      Security Benefits
                    </h4>
                    <ul
                      style={{
                        fontSize: '14px',
                        color: 'rgb(46, 46, 46)',
                        fontFamily:
                          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                      }}
                    >
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Block Popups
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Easily block popup notifications from unknown sites.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Security Check
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Checks for issues with AV, firewall, or Chrome
                          extensions.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Uninstall Browser Extensions
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Find and remove old or wanted browser extensions.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Router Security
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -checks if the network is secure.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Privacy scan
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Protect your privacy by finding and removing all
                          traces of online and offline activities.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Optimize Privacy Settings
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Review important Windows privacy settings and easily
                          change settings for maximum privacy.
                        </p>
                      </li>
                    </ul>
                    <h4
                      style={{
                        marginBottom: '10px',
                        fontSize: '1.1rem',
                        color: 'rgb(46, 46, 46)',
                        fontFamily:
                          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                      }}
                    >
                      Optimization Benefits
                    </h4>
                    <ul
                      style={{
                        fontSize: '14px',
                        color: 'rgb(46, 46, 46)',
                        fontFamily:
                          '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
                      }}
                    >
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Boost Startup
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Review and remove programs loaded at Startup to
                          increase Boot-up time.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Uninstall programs
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Cleanly uninstall programs along with any leftover
                          files or registry items.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Windows Repair
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Identify and automatically repair Windows issues.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Advanced Scan
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Cleans up additional files, including old Windows
                          updates, service packs, and old restore points.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Memory Boost
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Automatically shuts down background applications for
                          an immediate memory boost.
                        </p>
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Scans for programs using more than 30MB of memory.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Optimize SSD Settings
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Helps maximize performance by following recommended
                          system settings.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Analyze Disk
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -See how disk space is allocated on the PC.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        System Monitor Log
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Review recent notifications regarding possible PC
                          performance issues.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Windows Tools
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Quick access to most popular Windows optimization
                          tools.
                        </p>
                      </li>
                      <li
                        style={{
                          marginBottom: '10px',
                          listStyleType: 'disc',
                          fontWeight: 'bold',
                        }}
                      >
                        Stability Report
                        <p
                          style={{
                            marginTop: '5px',
                            fontSize: '1rem',
                            lineHeight: '1.3',
                            fontWeight: 'normal',
                          }}
                        >
                          -Breaks down the total number and frequency of
                          application crashes to help spot potential issues.
                        </p>
                      </li>
                    </ul>
                    .
                  </div>
                </details>

                <details className="spoilers-block__item">
                  <summary className="spoilers-block__title">
                    How do I use TotalSystemCare?
                  </summary>
                  <div className="spoilers-block__body">
                    All you need to do is download and run TotalSystemCare to
                    get started. You&apos;ll be prompted what to do in the
                    software every step of the way. Click{' '}
                    <a href="/download">
                      <u>here</u>
                    </a>{' '}
                    to get started now.
                  </div>
                </details>

                <details className="spoilers-block__item">
                  <summary className="spoilers-block__title">
                    What if I need help after I download TotalSystemCare?
                  </summary>
                  <div className="spoilers-block__body">
                    Don&apos;t worry, we have customer care representatives
                    standing by to help answer any questions and assist you with
                    any related issues you may be having with the software or
                    with your PC.
                  </div>
                </details>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
