import Header from './Header'
import Footer from './Footer'

import config from 'config'

import type { FC, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

const Layout: FC<Props> = ({ children }) => {
  return (
    <div className="wrapper">
      <Header />
      <main className="page">{children}</main>
      <Footer config={config} />
    </div>
  )
}

export default Layout
