import { useState } from 'react'

export default function Header() {
  const [burgerMenuOpen, setBurgerMenuOpen] = useState(false)

  return (
    <header className="header">
      <a href="/" className="header__logo">
        <img src="/img/home/main-logo.svg" alt="logo" />
      </a>
      <ul className={`header__menu ${burgerMenuOpen ? 'active' : ''}`}>
        <li>
          <a href="/about">About</a>
        </li>
        <li>
          <a href="/" className="header__menu-link anchor">
            HUB
          </a>
        </li>
        <li>
          <a href="/download" className="header__menu-link">
            Download
          </a>
        </li>
        <li>
          <a
            href="https://connect.safebytes.com/bridge/tsc/main/purchase/"
            className="header__menu-link"
          >
            Purchase
          </a>
        </li>
        <li>
          <a
            href="https://portal.safebytes.com/?app=tsc"
            className="header__menu-link"
          >
            Contact
          </a>
        </li>
      </ul>
      <div className="button-wrapper">
        <a
          href="https://customer.appesteem.com/certified?vendor=TSCAR"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/img/home/appesteem.png" alt="" />
        </a>
        <a
          href="https://www.trustedsite.com/verify?host=safebytes.com"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/img/home/trusted-logo.svg" alt="" />
        </a>
      </div>
      <button
        className={`icon-menu ${burgerMenuOpen ? 'active' : ''}`}
        onClick={() => setBurgerMenuOpen(!burgerMenuOpen)}
      >
        <span></span>
      </button>
    </header>
  )
}
